import Link from "next/link";

const Breadcrumb = ({ href, label }) => {
  return (
    <Link href={`${href}`}>
      <div className="flex items-center">
        <a className="cursor-pointer hover:underline">{label}</a>
      </div>
    </Link>
  )
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
export default function Breadcrumbs({ breadcrumbs }) {
  return (
    <div className="text-sm font-mazzard breadcrumbs">
      <ul>
        { breadcrumbs.map((breadcrumb, index) => (
          <li key={index}>
            <Breadcrumb
              href={breadcrumb.href}
              label={breadcrumb.label}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}