import Layout from "../layout/layout";
import Container from "../Container";
import Block from "../Block";
import NavBar from "../navbar/NavBar";
import {BuilderComponent} from "../../lib/builder";
import BackButton from "./BackButton";
import Breadcrumbs from "./Breadcrumbs";


/**
 * @param divider
 * @returns {undefined}
 */
export default function BuilderPage({page, menu, showBackButton = false, model = 'page', breadcrumbs = null}) {
  return (
    <Layout showFooter={false}>
      <Container>
        <Block>
          <NavBar menu={menu} />
        </Block>
        <Block>
          {showBackButton &&
            <div className="absolute z-10 left-0 float-left mx-2 my-2">
              <BackButton styleType='white'/>
            </div>
          }
          {breadcrumbs &&
            <Block className="p-4">
              <Breadcrumbs breadcrumbs={breadcrumbs}/>
            </Block>
          }
          <BuilderComponent
            content={page}
            model={model}
          />
        </Block>
      </Container>
    </Layout>
  );
}

