import {builder} from "../lib/builder";
import BuilderPage from "../components/common/BuilderPage";
import {getMenuByHandle} from "../lib/resources/menu";
import axiosStorefrontInstance from "../lib/axios-instances/axios-storefront-instance";

/**
 * @param params
 * @returns {Promise<{revalidate: number, props: {page: (*|null)}}>}
 */
export async function getStaticProps({ params }) {
  const page = await builder
    .get('page', {
      userAttributes: {
        urlPath: '/' + (params?.page?.join('/') || ''),
      },
    }).toPromise();

  return {
    props: {
      page: page || null,
      menu: await getMenuByHandle(axiosStorefrontInstance, {handle: 'main'}),

    },
  };
}

export default function Index({ page, menu }) {
  return (
    <BuilderPage menu={menu} page={page} />
  );
}

// export async function getStaticPaths() {
//   const pages = await builder.getAll('page', {
//     options: { noTargeting: true },
//     omit: 'data.blocks',
//   });
//
//   return {
//     // paths: pages.map(page => `${page.data?.url}`),
//     paths: ['/'],
//     // fallback: true,
//     fallback: 'blocking'
//   };
// }
